<script>
  import AppBar from '@/components/general/app-bar/AppBar.vue';
  import getErrorMessage from '@/utils/getErrorMessage';

  import { mapActions, mapGetters } from 'vuex';

  export default {
    data() {
      return {
        email: '',
        labelText: "Enter your <span class='font-weight-bold'>work email address</span>",
        isSendingPasswordResetEmail: false,
        isEmailSentSuccessfully: false,
        isEmailNotSent: false,
        errorMessage: '',
      };
    },
    components: {
      AppBar,
    },
    computed: {
      ...mapGetters('OrganizationsModule', {
        organizationLogo: 'getOrganizationLogoUrl',
      }),
    },
    methods: {
      ...mapActions('AuthModule', ['sendPasswordResetEmail']),
      async sendEmail() {
        this.isSendingPasswordResetEmail = true;
        this.isEmailSentSuccessfully = false;
        this.isEmailNotSent = false;
        this.errorMessage = '';

        try {
          await this.sendPasswordResetEmail(this.email);
          this.isEmailSentSuccessfully = true;
        } catch (error) {
          this.isEmailNotSent = true;
          this.errorMessage = getErrorMessage(error);
        } finally {
          this.isSendingPasswordResetEmail = false;
        }
      },
    },
  };
</script>
