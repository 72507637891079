<template>
  <v-row
    class="main-container"
    no-gutters>
    <v-col cols="5">
      <CurvedHeader
        title="FORGOTTEN YOUR PASSWORD?"
        organization-logo="organizationLogo" />
    </v-col>
    <v-col
      class="ma-auto"
      cols="4">
      <app-bar title="Go Back"></app-bar>
      <v-form @submit.prevent="sendEmail">
        <v-row no-gutters>
          <v-text-field
            color="primaryColor"
            class="my-4 px-4"
            type="email"
            v-model="email">
            <template slot="label"> Enter your <span class="font-weight-bold">work email address</span> </template>
          </v-text-field>
        </v-row>

        <v-row
          justify="center"
          no-gutters>
          <v-col cols="10">
            <v-btn
              type="submit"
              class="brand-gradient"
              :loading="isSendingPasswordResetEmail"
              :disabled="isSendingPasswordResetEmail"
              dark
              rounded
              block
              large
              depressed
              >Send reset email</v-btn
            >
          </v-col>
        </v-row>
      </v-form>

      <div
        class="reset-password-email-success mt-10 px-6"
        v-if="isEmailSentSuccessfully">
        Password reset email sent. Please check your email and click the link.
      </div>

      <div
        class="reset-password-email-error mt-10 px-6"
        v-if="isEmailNotSent">
        No account found with that email address. Something wrong? Contact app ondemand@weareluminate.co
        <!-- for future to put backend messages, for now I'll stick with the message from figma -->
        <span v-if="false">{{ errorMessage }}</span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import ForgotPasswordMixin from '@/views/auth/forgot-password/ForgotPassword.mixin.vue';
  import CurvedHeader from '@/components/general/curved-header/CurvedHeader.vue';
  export default {
    mixins: [ForgotPasswordMixin],
    components: {
      CurvedHeader, //we don't have the CurvedHeader in mobile that's why we didn't imported to the mixin
    },
  };
</script>

<style lang="scss" scoped>
  .main-container {
    height: 100%;
  }
  .reset-password-email-success,
  .reset-password-email-error {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  .reset-password-email-success {
    background: linear-gradient(to right, var(--v-primaryColor-base), var(--v-secondaryColor-base));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .reset-password-email-error {
    color: var(--v-errorDark-base);
  }
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16em;
  }
  .theme--light.v-label {
    color: var(--v-brandGrey2-base) !important;
  }

  ::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(51, 51, 51, 0.161) !important;
  }
</style>
